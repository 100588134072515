import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import EcoverTitle from './EcoverTitle';
import EcoverImage from './EcoverImage';

import ButtonContainer from '../../shared/ButtonContainer';
import ButtonLink from '../../shared/ButtonLink';

import _styles from './ecover.module.scss';

const leadMagnetUrl = `https://mcusercontent.com/74755ec65135c45a5ccd574fd/files/5033a6a8-8673-4031-aad4-c10c7bfe857d/the_outdoor_gardener_insiders_guide.pdf`;

const EcoverContent = () => {
  return (
    <>
      <Row className={`${_styles.titleRow}`}>
        <Col>
          <EcoverTitle />
        </Col>
      </Row>

      <Row className={`${_styles.imageRow}`}>
        <Col grow="1" style={{ maxWidth: '700px' }}>
          <EcoverImage />
        </Col>
      </Row>

      <Row justify="Center">
        <Col>
          <ButtonContainer>
            <ButtonLink href={leadMagnetUrl}>Download</ButtonLink>
          </ButtonContainer>
        </Col>
      </Row>
    </>
  );
};

export default EcoverContent;
