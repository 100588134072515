import React from 'react';

import _styles from './ecover-title.module.scss';

const EcoverTitle = () => {
  return (
    <h2 className={`${_styles.title}`}>
      Download Your Free Copy Now!
    </h2>
  );
};

export default EcoverTitle;
