import React from 'react';
import PropTypes from 'prop-types';

import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';

const ButtonLink = ({ href, children, className, id }) => {
  return (
    <a
      id={id}
      href={href}
      rel="noopener"
      className={`${_buttonStyles.asButton} ${_buttonStyles.secondary} ${_buttonStyles.largeV2} ${className}`}
    >
      {children}
    </a>
  );
};

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
};

ButtonLink.defaultProps = {
  id: "button-link",
  className: "js-insiders-guide-download"
}

export default ButtonLink;
