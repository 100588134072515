import React from 'react';

import Layout from 'gatsby-theme-kalanso-ui/src/components/Layout';
import SEO from 'gatsby-theme-kalanso-ui/src/components/SEO';

import EcoverSection from './EcoverSection';
import EcoverContent from './EcoverContent';

const seoTitle = `Download Now`;

const InsidersGuideDownloadPage = () => {
  return (
    <>
      <SEO title={seoTitle} hideFromSEO={true} />
      <Layout withFooter={true}>
        <EcoverSection>
          <EcoverContent />
        </EcoverSection>
      </Layout>
    </>
  );
};

export default InsidersGuideDownloadPage;
